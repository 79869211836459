import { useContext, useEffect } from 'react';
import styles from './styles.module.css';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button } from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import CartItemView from '../utils/CartItemView';
import { ShopContext } from '../../contexts/ShopContext';
import { CartContext } from '../../contexts/CartContext';
import { DEFAULT_CURRENCY } from '../../const';

export default function CartList(props) {
  const { cart, dismissPopup } = props;
  const { shop } = useContext(ShopContext);
  const { itemsCount } = useContext(CartContext);
  const nextBtnPressed = () => {
    props.openPlaceOrderPopup();
  };

  useEffect(() => {
    if (itemsCount <= 0) {
      dismissPopup();
    }
  }, [itemsCount, dismissPopup]);

  return (
    <Dialog
      open={props.showPopup}
      onClose={props.dismissPopup}
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle id="alert-dialog-title">Your Cart</DialogTitle>
      <DialogContent className={styles.popupContentDiv}>
        {Object.keys(cart.products || []).map((cartProductKey) => (
          <CartItemView
            key={cartProductKey}
            shopId={props.shopId}
            cartProduct={cart.products[cartProductKey]}
          />
        ))}
      </DialogContent>
      <DialogActions style={{ justifyContent: 'space-between' }}>
        <div className={styles.CartTotalDiv}>
          <div>Subtotal:</div>
          <div>
            <b>
              {shop?.config?.currency || DEFAULT_CURRENCY} {cart.summary?.total}
            </b>
          </div>
        </div>
        <div>
          <Button style={{ color: red[600] }} onClick={props.dismissPopup}>
            CLOSE
          </Button>
          <Button style={{ color: green[600] }} onClick={nextBtnPressed}>
            NEXT
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}
