import { SHOP_SOURCE } from '../const';
import YescloudProductService from './YescloudProductService';
import ApiProductService from './ApiProductService';
import CacheHelper from '../helpers/CacheHelper';

async function getCategories(shop) {
  const source = shop.config.source;

  const cacheKey = `${shop.id}-categories`;
  const cache = CacheHelper.get(cacheKey);
  if (cache) {
    return cache;
  }

  const data =
    source === SHOP_SOURCE.YESCLOUD
      ? await YescloudProductService.getCategories(shop)
      : await ApiProductService.getCategories(shop);

  CacheHelper.store(cacheKey, data);

  return data;
}

async function getCategory(shop, categoryId) {
  const source = shop.config.source;

  const cacheKey = `category-${shop.id}-${categoryId}`;
  const cache = CacheHelper.get(cacheKey);
  if (cache) {
    return cache;
  }

  const data =
    source === SHOP_SOURCE.YESCLOUD
      ? await YescloudProductService.getCategory(shop, categoryId)
      : await ApiProductService.getCategory(shop, categoryId);

  CacheHelper.store(cacheKey, data);

  return data;
}

async function getProducts(shop, category, searchKey) {
  const source = shop.config.source;

  const cacheKey = `${shop.id}-products-${category?.id ?? 'all'}-${searchKey}`;
  const cache = CacheHelper.get(cacheKey);
  if (cache) {
    return cache;
  }

  const data =
    source === SHOP_SOURCE.YESCLOUD
      ? await YescloudProductService.getProducts(shop, category, searchKey)
      : await ApiProductService.getProducts(shop, category, searchKey);

  CacheHelper.store(cacheKey, data);

  return data;
}

async function getProduct(shop, productId) {
  const source = shop.config.source;

  const cacheKey = `product-${shop.id}-${productId}`;
  const cache = CacheHelper.get(cacheKey);
  if (cache) {
    return cache;
  }

  const data =
    source === SHOP_SOURCE.YESCLOUD
      ? await YescloudProductService.getProduct(shop, productId)
      : await ApiProductService.getProduct(shop, productId);

  CacheHelper.store(cacheKey, data);

  return data;
}

const ProductService = {
  getProducts,
  getCategories,
  getCategory,
  getProduct,
};

export default ProductService;
