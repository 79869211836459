import React, { useCallback, useContext, useEffect, useState } from 'react';
import styles from './styles.module.css';
import TitleBar from '../../utils/TitleBar/TitleBar';
import { Grid, Hidden, Paper, Typography } from '@material-ui/core';
import { AlertMessagesContext } from 'react-alert-messages';
import { ROUTES, SERVER, STORAGE_KEY } from '../../../const';
import OrderService from '../../../services/OrderService';
import { useHistory, useParams } from 'react-router-dom';
import ProductService from '../../../services/ProductService';
import { ShopContext } from '../../../contexts/ShopContext';
import OrderItemView from '../../utils/OrderItemView/OrderItemView';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import Loader from '../../utils/Loading/index';
import QRCode from 'qrcode.react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

function OrderDetails() {
  const [orderDetails, setOrderDetails] = useState(null);
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [order, setOrder] = useState(null);

  const history = useHistory();
  const { postAlertMessage } = useContext(AlertMessagesContext);
  const { shop } = useContext(ShopContext);
  const { shopId, orderId } = useParams();
  const saleId = orderDetails?.id?.slice(-6);

  const getOrderDetailsFromServer = useCallback(
    async (sale) => {
      setIsLoading(true);
      try {
        const resp = await OrderService.getOrder(sale, shopId);
        setOrderDetails(resp?.data);
      } catch (error) {
        postAlertMessage({ text: error.message, type: 'failed' });
      }
      setIsLoading(false);
    },
    [postAlertMessage, shopId]
  );

  const getItems = useCallback(
    async (items) => {
      setIsLoading(true);
      try {
        const data = [];
        for (const item of items) {
          const response = await ProductService.getProduct(shop, item);
          data.push(response);
        }
        setProducts(data);
      } catch (error) {
        postAlertMessage({ text: error.message, type: 'failed' });
      }
      setIsLoading(false);
    },
    [postAlertMessage, shop]
  );

  const formatDateTime = (orderDate) => {
    if (!orderDate) return;
    const date = new Date(orderDate);

    const formattedDate = new Intl.DateTimeFormat('en', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    }).format(date);

    const formattedTime = new Intl.DateTimeFormat('en', {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
    }).format(date);

    return `${formattedDate}, ${formattedTime}`;
  };

  const handleBackToOrders = () => {
    history.push(ROUTES.ORDERS.replace(':shopId', shopId));
  };

  const handleBackToHome = () => {
    history.push(ROUTES.SHOP_HOME.replace(':shopId', shopId));
  };

  useEffect(() => {
    const sales = JSON.parse(localStorage.getItem(STORAGE_KEY.ORDERS));
    console.log(sales);
    const sale = sales.find((s) => s?.id === orderId) || {};
    setOrder(sale);
    getOrderDetailsFromServer(sale);
  }, [getOrderDetailsFromServer, orderId]);

  useEffect(() => {
    if (!orderDetails) return;
    const items = Object.keys(orderDetails?.items);
    getItems(items);
  }, [getItems, orderDetails]);

  return (
    <>
      <TitleBar />
      <Grid className={styles.ContentWrapper}>
        <Hidden smUp={true}>
          <Grid
            container
            justifyContent="space-between"
            style={{ margin: '4px 0px' }}
          >
            <Grid
              item
              onClick={handleBackToOrders}
              className={styles.backToMenu}
            >
              <ArrowBackIosIcon style={{ fontSize: '14px' }} />
              <span> Back to orders</span>
            </Grid>
            <Grid item onClick={handleBackToHome} className={styles.backToMenu}>
              <HomeRoundedIcon />
            </Grid>
          </Grid>
        </Hidden>
        {isLoading && <Loader isOpen={isLoading} />}
        <h2>Order Details</h2>
        <Paper className={styles.contentsPaper}>
          <Grid container justifyContent="space-evenly">
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              className={styles.detailsContainer}
            >
              <Grid container className={styles.infoContainer}>
                <Grid item xs={6} className={styles.title}>
                  <Typography variant="body2" gutterBottom>
                    Order ID
                  </Typography>
                </Grid>
                <Grid item xs={6} className={styles.value}>
                  <Typography variant="body2" gutterBottom>
                    {saleId || '-'}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container className={styles.infoContainer}>
                <Grid item xs={6} className={styles.title}>
                  <Typography variant="body2" gutterBottom>
                    Order Status
                  </Typography>
                </Grid>
                <Grid item xs={6} className={styles.value}>
                  <Typography variant="body2" gutterBottom>
                    {orderDetails?.status || '-'}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container className={styles.infoContainer}>
                <Grid item xs={6} className={styles.title}>
                  <Typography variant="body2" gutterBottom>
                    Table Name
                  </Typography>
                </Grid>
                <Grid item xs={6} className={styles.value}>
                  <Typography variant="body2" gutterBottom>
                    {orderDetails?.customer?.tableName || '-'}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container className={styles.infoContainer}>
                <Grid item xs={6} className={styles.title}>
                  <Typography variant="body2" gutterBottom>
                    Table Number
                  </Typography>
                </Grid>
                <Grid item xs={6} className={styles.value}>
                  <Typography variant="body2" gutterBottom>
                    {orderDetails?.customer?.tableNumber || '-'}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container className={styles.infoContainer}>
                <Grid item xs={6} className={styles.title}>
                  <Typography variant="body2" gutterBottom>
                    Date & Time
                  </Typography>
                </Grid>
                <Grid item xs={6} className={styles.value}>
                  <Typography variant="body2" gutterBottom>
                    {formatDateTime(orderDetails?.createdAt) || '-'}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container className={styles.infoContainer}>
                <Grid item xs={6} className={styles.title}>
                  <Typography variant="body2" gutterBottom>
                    Discount
                  </Typography>
                </Grid>
                <Grid item xs={6} className={styles.value}>
                  <Typography variant="body2" gutterBottom>
                    {orderDetails?.discount || '-'}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container className={styles.infoContainer}>
                <Grid item xs={6} className={styles.title}>
                  <Typography variant="body2" gutterBottom>
                    Amount
                  </Typography>
                </Grid>
                <Grid item xs={6} className={styles.value}>
                  <Typography variant="body2" gutterBottom>
                    {orderDetails?.payable || '-'}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6} className={styles.qrContainer}>
              <QRCode
                className={styles.qrContainer}
                value={JSON.stringify({ ...order, url: SERVER.URL })}
                size={150}
              />
            </Grid>
          </Grid>
        </Paper>
        <Grid container spacing={1}>
          {products.map((product) => {
            return (
              <OrderItemView
                product={product}
                data={orderDetails?.items}
                key={product?.id}
              />
            );
          })}
        </Grid>
      </Grid>
    </>
  );
}

export default OrderDetails;
