import { Route } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import { ShopContext } from '../../contexts/ShopContext';
import ShopService from '../../services/ShopService';

export default function ShopRoute({ component: Component, ...rest }) {
  const { shop, setShop } = useContext(ShopContext);

  const updateShopDetails = () => {
    async function loadShopFromServer() {
      const shop = await ShopService.getShopDetails(shopId);
      setShop(shop);
    }
    loadShopFromServer();
  };

  const shopId = rest?.computedMatch?.params?.shopId;
  useEffect(updateShopDetails, [setShop, shopId]);

  return (
    <Route
      {...rest}
      render={(routeProps) => {
        return shop ? <Component {...routeProps} /> : <div>Loading...</div>;
      }}
    ></Route>
  );
}
