import ProductListView from '../utils/ProductList/ProductListView';
import { Dialog } from '@material-ui/core';
import React, { useState } from 'react';
import TitleBar from './TitleBar/TitleBar';

export default function SearchList(props) {
  const [searchKey, setSearchKey] = useState('');
  const [numberOfProducts, setNumberOfProducts] = useState(0);
  const fnOnTextChange = (text) => {
    setSearchKey(text);
  };
  const fnOnResultChange = (num) => {
    setNumberOfProducts(num);
  };
  const { show, fnOnClose } = props;

  return (
    <Dialog fullScreen open={!!show}>
      <TitleBar
        search={true}
        fnOnClose={fnOnClose}
        fnOnSearchTextChange={fnOnTextChange}
      />
      <div
        style={{
          margin: 20,
          padding: 20,
          color: 'white',
          textAlign: 'center',
          backgroundColor: '#1470d5',
        }}
      >
        {searchKey && (
          <div>
            <div>Showing result for '{searchKey}',</div>
            <div>Found {numberOfProducts} result</div>
          </div>
        )}
        {!searchKey && (
          <div>Type in the search box to get matching products</div>
        )}
      </div>
      <ProductListView
        searchKey={searchKey}
        fnOnResultChange={fnOnResultChange}
      />
    </Dialog>
  );
}
