import React from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import PageNotFound from './components/pages/PageNotFoundPage/PageNotFound';
import { ShopProvider } from './contexts/ShopContext';
import ShopHomePage from './components/pages/ShopHomePage';
import ProductDetailsPage from './components/pages/ProductDetailsPage';
import ShopRoute from './components/utils/ShopRoute';
import { CartProvider } from './contexts/CartContext';
import AlertMessagesProvider from 'react-alert-messages';
import OrderPage from './components/pages/OrderPage/OrderPage';
import OrderDetails from './components/pages/OrderDetails/OrderDetails';
import { ROUTES } from './const';

function App() {
  return (
    <AlertMessagesProvider>
      <ShopProvider>
        <CartProvider>
          <BrowserRouter>
            <Switch>
              <ShopRoute
                exact={true}
                path={ROUTES.SHOP_HOME}
                component={ShopHomePage}
              />
              <ShopRoute
                exact={true}
                path={ROUTES.ORDERS}
                component={OrderPage}
              />
              <ShopRoute
                exact={true}
                path={ROUTES.ORDER_DETAILS}
                component={OrderDetails}
              />
              <ShopRoute
                exact={true}
                path={ROUTES.PRODUCT_DETAILS}
                component={ProductDetailsPage}
              />
              <Route path="/*">
                <PageNotFound />
              </Route>
            </Switch>
          </BrowserRouter>
        </CartProvider>
      </ShopProvider>
    </AlertMessagesProvider>
  );
}

export default App;
