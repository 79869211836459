import React from 'react';
import config from '../../config';
import ProductService from '../../services/ProductService';
import CategoryView from './CategoryView';

const styles = {
  categoriesWrapper: {
    padding: '8px',
    display: 'flex',
    width: 'auto',
    overflowY: 'hidden',
    flexWrap: 'nowrap',
    overflowX: 'auto',
    flex: 'none',
    boxShadow: '0 4px 2px -2px #ececec',
    borderBottom: '1px solid #ececec',
    backgroundColor: '#fff',
  },
};

const allCategory = {
  id: 'all',
  name: 'ALL',
  selected: true,
  image:
    'https://storage.googleapis.com/sanoft-eazy-cart.appspot.com/static/images/all_cateogries.svg',
};

export default class CategoryListView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
    };
  }

  categorySelected = (category) => {
    this.props.fcnSetSelectedCategory(category);
    this.updateSelectedCategory(category);
  };

  updateSelectedCategory = (category) => {
    const categories = this.state.categories;
    categories.forEach((c) => (c.selected = false));
    categories.find((c) => c.id === category.id).selected = true;
    this.setState({ categories });
  };

  async componentDidMount() {
    try {
      const shop = this.props.shop;
      const categories = await ProductService.getCategories(shop);
      const shopConfig = shop.meta || config.meta;
      if (shopConfig.allCategoryRequired) {
        categories.unshift(allCategory);
      } else if (categories.length) {
        const category = categories[0];
        category.selected = true;
        this.props.fcnSetSelectedCategory(category);
      }
      this.setState({ categories });
    } catch (error) {
      console.error(error.message);
    }
  }

  render() {
    const categories = this.state.categories;
    return (
      <div style={styles.categoriesWrapper}>
        {categories.map((category) => {
          return (
            <CategoryView
              key={category.id}
              category={category}
              shop={this.props.shop}
              categorySelected={this.categorySelected}
            />
          );
        })}
      </div>
    );
  }
}
