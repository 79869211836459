import axios from 'axios';
import { SERVER } from '../const';

async function getCategories(shop) {
  const resp = await axios.get(`${SERVER.URL}/public/categories`, {
    params: { orgId: shop.id },
  });
  return resp.data;
}

async function getCategory(shop, categoryId) {
  const resp = await axios.get(
    `${SERVER.URL}/public/categories/${categoryId}`,
    {
      params: { orgId: shop.id },
    }
  );
  return resp.data;
}

async function getProducts(shop, category, searchKey) {
  const resp = await axios.get(`${SERVER.URL}/public/products`, {
    params: { orgId: shop.id, categoryId: category?.id, searchKey },
  });
  return resp.data;
}

async function getProduct(shop, productId) {
  const resp = await axios.get(`${SERVER.URL}/public/products/${productId}`, {
    params: { orgId: shop.id },
  });
  return resp.data;
}

const ProductService = {
  getProducts,
  getCategories,
  getCategory,
  getProduct,
};

export default ProductService;
