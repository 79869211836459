export function getDateStr(date) {
  let day = date.getDate();
  if (day < 10) {
    day = '0' + day;
  }

  let month = date.getMonth() + 1;
  if (month < 10) {
    month = '0' + month;
  }
  return `${day}/${month}/${date.getFullYear()}`;
}

export function getTimeStr(date) {
  let hr = date.getHours();
  let ampm = 'AM';
  if (hr > 12) {
    hr -= 12;
    ampm = 'PM';
  }
  if (hr < 10) {
    hr = '0' + hr;
  }
  let min = date.getMinutes();
  if (min < 10) {
    min = '0' + min;
  }

  return `${hr}:${min} ${ampm}`;
}
