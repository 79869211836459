import axios from 'axios';
import { SERVER } from '../const';
import CacheHelper from '../helpers/CacheHelper';

async function getShopDetails(shopId) {
  const cacheKey = `shop-details-${shopId}`;
  const cache = CacheHelper.get(cacheKey);
  if (cache) {
    return cache;
  }

  const resp = await axios.get(`${SERVER.URL}/public/organizations/${shopId}`);
  const data = resp.data;

  CacheHelper.store(cacheKey, data);

  return data;
}

const ShopService = {
  getShopDetails,
};

export default ShopService;
