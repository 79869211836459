export const SHOP_SOURCE = {
  YESCLOUD: 'yescloud',
  API: 'API',
};

export const STORAGE_KEY = {
  CART: 'cart',
  CUSTOMER_ID: 'customer-id',
  ORDERS: 'orders',
};

export const SERVER = {
  URL:
    process.env.REACT_APP_BASE_API_URL ||
    'https://sanoft-mini-erp.uc.r.appspot.com/api',
};

export const DEFAULT_CURRENCY = 'Rs';

export const ROUTES = {
  SHOP_HOME: '/:shopId',
  ORDERS: '/:shopId/orders',
  ORDER_DETAILS: '/:shopId/orders/:orderId',
  PRODUCT_DETAILS: '/:shopId/:categoryId/:productId',
};
