const CACHE_DURATION = 5 * 60 * 1000;

const cache = {};

function get(key) {
  const cacheDetails = cache[key];
  if (!cacheDetails) {
    return;
  }

  if (cacheDetails.expiry > Date.now()) {
    return cacheDetails.data;
  }
}

function store(key, data) {
  cache[key] = { data, expiry: Date.now() + CACHE_DURATION };
}

const CacheHelper = {
  get,
  store,
};

export default CacheHelper;
