import styles from './styles.module.css';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

export default function OrderStatusDialog({ showPopup, order, fnOnClose }) {
  return (
    <Dialog open={showPopup} onClose={fnOnClose} maxWidth="sm" fullWidth>
      <DialogTitle className={styles.statusDialogBox}>
        <Grid style={{ padding: 16, textAlign: 'center' }}>
          <Grid item xs={12}>
            <CheckCircleOutlineIcon className={styles.tickArrow} />
          </Grid>
          <h5>YOUR ORDER HAS BEEN PLACED SUCCESSFULLY!</h5>
          <h6>
            We will call you for followups. Please use this '{order?.id}' as
            your order reference
          </h6>
          <Button
            variant="contained"
            style={{ background: green[600], color: 'white' }}
            onClick={fnOnClose}
          >
            DONE
          </Button>
        </Grid>
      </DialogTitle>
    </Dialog>
  );
}
