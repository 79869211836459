import React from 'react';
import styles from './Order.module.css';
import { Paper, Grid, Typography } from '@material-ui/core';

function OrderList(props) {
  const { data, link } = props;
  const orderId = data?.id.slice(-6);

  const formatDateTime = (orderDate) => {
    const date = new Date(orderDate);

    const formattedDate = new Intl.DateTimeFormat('en', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    }).format(date);

    const formattedTime = new Intl.DateTimeFormat('en', {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
    }).format(date);

    return `${formattedDate}, ${formattedTime}`;
  };

  return (
    <Grid
      item
      xs={12}
      sm={6}
      md={4}
      lg={3}
      className={styles.listItemOrderCard}
    >
      <Paper className={styles.orderWrapper} onClick={link}>
        <div className={styles.orderData}>
          <Grid container>
            <Grid item xs={5} className={styles.title}>
              <Typography variant="body2" gutterBottom>
                Order ID
              </Typography>
              <Typography variant="body2" gutterBottom>
                :
              </Typography>
            </Grid>
            <Grid item xs={7} className={styles.value}>
              <Typography variant="body2" gutterBottom>
                {orderId || '-'}
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={5} className={styles.title}>
              <Typography variant="body2" gutterBottom>
                Order Status
              </Typography>
              <Typography variant="body2" gutterBottom>
                :
              </Typography>
            </Grid>
            <Grid item xs={7} className={styles.value}>
              <Typography variant="body2" gutterBottom>
                {data?.status || '-'}
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={5} className={styles.title}>
              <Typography variant="body2" gutterBottom>
                Table Name
              </Typography>
              <Typography variant="body2" gutterBottom>
                :
              </Typography>
            </Grid>
            <Grid item xs={7} className={styles.value}>
              <Typography variant="body2" gutterBottom>
                {data?.customer?.tableName || '-'}
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={5} className={styles.title}>
              <Typography variant="body2" gutterBottom>
                Table Number
              </Typography>
              <Typography variant="body2" gutterBottom>
                :
              </Typography>
            </Grid>
            <Grid item xs={7} className={styles.value}>
              <Typography variant="body2" gutterBottom>
                {data?.customer?.tableNumber || '-'}
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={5} className={styles.title}>
              <Typography variant="body2" gutterBottom>
                Date & Time
              </Typography>
              <Typography variant="body2" gutterBottom>
                :
              </Typography>
            </Grid>
            <Grid item xs={7} className={styles.value}>
              <Typography variant="body2" gutterBottom>
                {formatDateTime(data?.createdAt) || '-'}
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={5} className={styles.title}>
              <Typography variant="body2" gutterBottom>
                Amount
              </Typography>
              <Typography variant="body2" gutterBottom>
                :
              </Typography>
            </Grid>
            <Grid item xs={7} className={styles.value}>
              <Typography variant="body2" gutterBottom>
                {data?.payable || '-'}
              </Typography>
            </Grid>
          </Grid>
        </div>
        {/* <div>
          <IconButton>
            <ChevronRightRoundedIcon
              onClick={link}
              className={styles.orderDetailsBtn}
            />
          </IconButton>
        </div> */}
      </Paper>
    </Grid>
  );
}

export default OrderList;
