import { createContext, useEffect, useState } from 'react';
import config from '../config';

export const ShopContext = createContext();

export function ShopProvider(props) {
  const [shop, setShop] = useState(null);
  const [theme, setTheme] = useState(null);

  useEffect(() => {
    setTheme({ color: shop?.config?.color || config.color });
  }, [shop?.config?.color]);

  return (
    <ShopContext.Provider value={{ shop, setShop, theme }}>
      {props.children}
    </ShopContext.Provider>
  );
}
