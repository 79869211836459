import React, { useCallback, useContext, useEffect, useState } from 'react';
import { ShopContext } from '../../contexts/ShopContext';
import ProductService from '../../services/ProductService';
import TitleBar from '../utils/TitleBar/TitleBar';
import styles from './styles.module.css';
import { Button, Container, Grid } from '@material-ui/core';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { ReactComponent as ReduceShoppingCart } from '../utils/ProductView/ReduceShoppingCart.svg';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CartService from '../../services/CartService';
import CategoryListView from '../utils/CategoryListView';
import { getProductImageSrc } from '../../helpers/ProductHelper';
import { CartContext } from '../../contexts/CartContext';
import AddToCartDialog from '../popups/AddToCartDialog';

export default function ProductDetailsPage(props) {
  const { shop } = useContext(ShopContext);
  const { addItemToCart, reduceItemFromCart } = useContext(CartContext);
  const [category, setCategory] = useState(null);
  const [product, setProduct] = useState(null);
  const [cartQty, setCartQty] = useState(0);
  const [showEditQuantityDialog, setShowEditQuantityDialog] = useState(false);

  const { shopId, categoryId, productId } = props?.match?.params;

  const cartChangedCallback = useCallback(
    (cart) => {
      const cartProduct = cart.products[productId];
      setCartQty(cartProduct?.quantity || 0);
    },
    [productId]
  );

  useEffect(() => {
    if (!shop || !categoryId) {
      return;
    }

    async function loadCategory() {
      const category = await ProductService.getCategory(shop, categoryId);
      setCategory(category);
    }

    loadCategory();
  }, [shop, categoryId]);

  useEffect(() => {
    if (!shop || !productId) {
      return;
    }

    async function loadProduct() {
      const product = await ProductService.getProduct(shop, productId);
      setProduct(product);
    }

    loadProduct();
  }, [shop, productId]);

  useEffect(() => {
    CartService.registerCallback(shopId, cartChangedCallback, false);
    return () => {
      CartService.deRegisterCallback(cartChangedCallback);
    };
  }, [shopId, cartChangedCallback]);

  if (shop.id !== shopId) {
    return <div>Invalid shop data! Please refresh the page</div>;
  }

  const addToCartBtnPressed = () => {
    addItemToCart(product);
  };
  const removeToCartBtnPressed = () => {
    reduceItemFromCart(product);
  };

  return (
    <div>
      <TitleBar shop={shop} />

      <div
        onClick={() => {
          props.history.push(`/${shop.id}`);
        }}
      >
        <CategoryListView shop={shop} fcnSetSelectedCategory={setCategory} />
      </div>
      {!product || !category ? (
        <div>Loading...</div>
      ) : (
        <Container maxWidth="lg">
          <Grid container className={styles.productDetailWrapper}>
            <Grid item xs={12} md={4} lg={5} className={styles.productImgDiv}>
              <img
                src={getProductImageSrc(product)}
                alt={product.name}
                className={styles.productImg}
              />
            </Grid>
            <Grid item sm={12} md={1} style={{ minHeight: 40 }}></Grid>
            <Grid
              item
              xs={12}
              md={7}
              lg={6}
              className={styles.productContentDiv}
            >
              <h2 className={styles.productName}>{product.name}</h2>
              <span>{product.description}</span>
              <div className={styles.priceWrapper}>
                <span className={styles.productMrp}>MRP: {product.price}</span>
                <span className={styles.productPrice}>
                  Price: {product.price - (product.discountPerUnit || 0)}
                </span>
              </div>
              <div className={styles.cartButton}>
                {cartQty <= 0 ? (
                  <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    className={styles.addToCartBtnText}
                    onClick={addToCartBtnPressed}
                  >
                    Add to Cart
                  </Button>
                ) : (
                  <React.Fragment>
                    <Button variant="contained" color="secondary" size="small">
                      <ReduceShoppingCart onClick={removeToCartBtnPressed} />
                    </Button>
                    <div className={styles.cartQuantityText}>
                      <span>{cartQty}</span>
                    </div>
                    <div className={styles.cartQuantityEditLayout}>
                      <EditOutlinedIcon
                        onClick={() => {
                          setShowEditQuantityDialog(true);
                        }}
                      />
                    </div>
                    <Button variant="contained" color="secondary" size="small">
                      <AddShoppingCartIcon onClick={addToCartBtnPressed} />
                    </Button>
                  </React.Fragment>
                )}
              </div>
            </Grid>
          </Grid>
        </Container>
      )}

      <AddToCartDialog
        product={product}
        showPopup={showEditQuantityDialog}
        dismissPopup={() => {
          setShowEditQuantityDialog(false);
        }}
      />
    </div>
  );
}
