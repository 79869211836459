const config = {
  color: {
    primary: '#1ebf5a',
  },
  meta: {
    allCategoryRequired: false,
    placeOrderOverWhatsapp: false,
    source: 'API',
    yescloudShopId: 0,
  },
};

export default config;
