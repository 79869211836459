import { Component } from 'react';
import styles from './styles.module.css';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button } from '@material-ui/core';
import { red, green } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

export default class AddToCartDialog extends Component {
  constructor(props) {
    super(props);
    this.props = { showPopup: false };
  }

  formValueChanged = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  render() {
    return (
      <Dialog
        open={this.props.showPopup}
        onClose={this.props.dismissPopup}
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-title">{'Add To Cart'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div className={styles.cartDiv}>
              <div className={styles.cartLeft}>
                <img
                  className={styles.productImg}
                  src="https://veemartonline.com/Categories/32.jpg"
                  alt="ShortName"
                />
              </div>
              <div className={styles.cartRight}>
                <h3>TOMATO</h3>
                <h5>Meadium </h5>
                <div className={styles.price}>
                  <span className={styles.priceOld}>₹50</span>
                  <span>₹40 x</span>
                  <input
                    className={styles.qtyInput}
                    placeholder="Qty"
                    type="number"
                  />
                  <span> = 40</span>
                  <div className={styles.deleteIcon}>
                    <IconButton aria-label="delete">
                      <DeleteIcon />
                    </IconButton>
                  </div>
                </div>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button style={{ color: red[600] }} onClick={this.props.dismissPopup}>
            CLOSE
          </Button>
          <Button
            style={{ color: green[600] }}
            onClick={this.props.dismissPopup}
            autoFocus
          >
            SAVE
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
