import { getDateStr, getTimeStr } from './DateFormatHelper';
import { DEFAULT_CURRENCY } from '../const';

export function formatCart(cart, shop, customer) {
  const date = new Date();

  return (
    `*${shop.name}*\n` +
    (shop.address || []).join('\n') +
    '\n\nI would like to get these items.\n\n' +
    `Time: *${getTimeStr(date)}*\n` +
    `Date: *${getDateStr(date)}*\n` +
    `${Object.entries(customer)
      .map(([key, value]) => `${toReadableCase(key)}: *${value}*`)
      .join('\n')}` +
    '\n---------------------------------------------\n' +
    '*Products*' +
    '\n---------------------------------------------\n' +
    `${Object.keys(cart.products)
      .map((cartProductId) => {
        const cartProduct = cart.products[cartProductId];
        const product = cartProduct.product;
        return `*${product.name}*\t${
          shop?.config?.currency || DEFAULT_CURRENCY
        } ${product.price} X ${cartProduct.quantity} *${
          shop?.config?.currency || DEFAULT_CURRENCY
        } ${product.price * cartProduct.quantity}*\n`;
      })
      .join('\n')}` +
    '---------------------------------------------\n' +
    `Amount : *${shop?.config?.currency || DEFAULT_CURRENCY} ${
      cart.summary.total
    }*` +
    `\nDiscount : *${shop?.config?.currency || DEFAULT_CURRENCY} ${
      cart.summary.discount
    }*\n` +
    `\n*TOTAL PAYABLE : ${shop?.config?.currency || DEFAULT_CURRENCY}  ${
      cart.summary.payable
    }*` +
    '\n\nPlease confirm by reply.'
  );
}

function toReadableCase(str) {
  return str[0].toUpperCase() + str.slice(1).toLowerCase();
}
