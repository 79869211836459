import {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from 'react';
import { ShopContext } from './ShopContext';
import CartHelper from '../helpers/CartHelper';

export const CartContext = createContext();

export function CartProvider(props) {
  const { shop } = useContext(ShopContext);
  const [cart, setCart] = useState(null);
  const [itemsCount, setItemsCount] = useState(0);

  const getCart = useCallback(async () => {
    const _cart = await CartHelper.getCart(shop?.id);
    setCart(_cart);
  }, [shop?.id]);

  const countCartItem = useCallback(async () => {
    const count = await CartHelper.cartItemCount(cart);
    setItemsCount(count);
  }, [cart]);

  const addItemToCart = async (product) => {
    const quantityDelta = (shop.options || {}).defaultQuantityStep || 1;
    await CartHelper.addToCart(shop.id, product, quantityDelta);
    await getCart();
    await countCartItem(cart);
  };

  const clearCartItems = async () => {
    CartHelper.clearCart(shop?.id);
    await getCart();
    await countCartItem(cart);
  };

  const removeItemFromCart = async (prodect) => {
    CartHelper.removeFromCart(shop?.id, prodect);
    await getCart();
    await countCartItem(cart);
  };

  const reduceItemFromCart = async (product) => {
    const quantityDelta = (shop.options || {}).defaultQuantityStep || 1;
    CartHelper.reduceFromCart(shop?.id, product, quantityDelta);
    await getCart();
    await countCartItem(cart);
  };

  const setQtyInCart = async (product, quantity) => {
    await CartHelper.setQtyInCart(shop?.id, product, quantity);
    await getCart();
    await countCartItem(cart);
  };

  useEffect(() => {
    if (!shop?.id) return;
    getCart();
    countCartItem();
  }, [getCart, countCartItem, shop?.id]);

  return (
    <CartContext.Provider
      value={{
        cart,
        itemsCount,
        addItemToCart,
        clearCartItems,
        removeItemFromCart,
        reduceItemFromCart,
        setQtyInCart,
      }}
    >
      {props.children}
    </CartContext.Provider>
  );
}
