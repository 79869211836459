import React, { useContext, useState } from 'react';
import styles from '../../App.module.css';
import CategoryListView from '../utils/CategoryListView';
import ProductListView from '../utils/ProductList/ProductListView';
import TitleBar from '../utils/TitleBar/TitleBar';
import { ShopContext } from '../../contexts/ShopContext';
import CartSummaryLayout from '../utils/CartSummaryLayout/CartSummaryLayout';

function ProductsPage(props) {
  const { shop } = useContext(ShopContext);
  const [selectedCategory, setSelectedCategory] = useState(undefined);

  const openProductDetailsPage = ({ shop, category, product }) => {
    props.history.push(`/${shop.id}/${category.id}/${product.id}`);
  };

  return (
    <React.Fragment>
      <TitleBar />
      <div className={styles.ContentsWrapper}>
        <div className={styles.ContentsArea}>
          <div className={styles.PageContent}>
            <CategoryListView
              shop={shop}
              fcnSetSelectedCategory={setSelectedCategory}
            />
            <ProductListView
              selectedCategory={selectedCategory}
              fnOpenProductDetailsPage={openProductDetailsPage}
            />
          </div>
        </div>
      </div>
      <div className={styles.CartSummaryLayout}>
        <CartSummaryLayout />
      </div>
    </React.Fragment>
  );
}

export default ProductsPage;
