import React, { useContext } from 'react';
import { withStyles } from '@material-ui/styles';
import { ShopContext } from '../../contexts/ShopContext';

const CATEGORY_SIDE = 80;
const SELECTED_BORDER = 3;

const styles = (theme) => ({
  categoryParent: {
    margin: '10px 10px 4px 10px',
    cursor: 'pointer',
  },
  categoryImageWrapper: {
    width: CATEGORY_SIDE,
    height: CATEGORY_SIDE,
    overflow: 'hidden',
    backgroundColor: '#035b96b0',
    borderRadius: '50%',
    flex: 'none',
    margin: '0 auto',
  },
  categoryImage: {
    width: '100%',
    height: '100%',
    '&:hover': {
      transform: 'scale(1.05)',
    },
  },
  categoryText: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '15px',
    marginTop: '10px',
  },
});

function getImageBorders(selected) {
  return selected
    ? {
        border: '1px solid white',
        height: 'calc(100% - 2px)',
        width: 'calc(100% - 2px)',
        borderRadius: '50%',
      }
    : {};
}

function getImageWrapperBorders(selected) {
  return selected
    ? {
        border: `${SELECTED_BORDER}px solid #1ebf5a`,
        width: CATEGORY_SIDE - 2 * SELECTED_BORDER,
        height: CATEGORY_SIDE - 2 * SELECTED_BORDER,
      }
    : {};
}

function CategoryView(props) {
  const { classes, category, categorySelected } = props;
  const { theme } = useContext(ShopContext);
  return (
    <div
      className={classes.categoryParent}
      onClick={() => {
        categorySelected(category);
      }}
    >
      <div
        className={classes.categoryImageWrapper}
        style={getImageWrapperBorders(category.selected)}
      >
        <img
          style={getImageBorders(category.selected)}
          className={classes.categoryImage}
          src={category.image}
          alt=""
        />
      </div>
      <div
        className={classes.categoryText}
        style={{ color: theme.color.primary }}
      >
        {category.name}
      </div>
    </div>
  );
}

export default withStyles(styles)(CategoryView);
