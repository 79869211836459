import styles from '../popups/styles.module.css';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { Grid, Hidden, Paper } from '@material-ui/core';
import { getProductImageSrc } from '../../helpers/ProductHelper';
import { ShopContext } from '../../contexts/ShopContext';
import { useContext } from 'react';
import { CartContext } from '../../contexts/CartContext';
import { DEFAULT_CURRENCY } from '../../const';

export default function CartItemView(props) {
  const { shop } = useContext(ShopContext);
  const { removeItemFromCart, setQtyInCart } = useContext(CartContext);

  const onClickRemoveItemFromCart = async () => {
    await removeItemFromCart(cartProduct.product);
  };

  const changeItemQtyInCart = async (event) => {
    const value = parseFloat(event.target.value);
    if (isNaN(value)) return;
    setQtyInCart(cartProduct.product, value);
  };
  const cartProduct = props.cartProduct;
  const product = cartProduct.product;
  return (
    <Paper elevation={1} className={styles.cartCard}>
      <Grid container>
        <Hidden xsDown>
          <Grid sm={3} item>
            <img
              className={styles.productImg}
              src={getProductImageSrc(product)}
              alt={product.name}
            />
          </Grid>
        </Hidden>
        <Grid xs={11} sm={8} item>
          <div className={styles.cartItemNameWrapper}>
            <h3>{product.name}</h3>
            <span>{product.description}</span>
          </div>
        </Grid>
        <Grid xs={1} sm={1} item>
          <IconButton
            size="small"
            aria-label="delete"
            onClick={onClickRemoveItemFromCart}
          >
            <DeleteIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Grid container style={{ marginTop: 10 }}>
        <Grid item xs={5} lg={7} className={styles.priceWrapper}>
          <Hidden smUp>
            {product.price !== product.price - (product.discount || 0) && (
              <span className={styles.priceOld}>
                {shop?.config?.currency || DEFAULT_CURRENCY} {product.price}
              </span>
            )}
            <span>
              {shop?.config?.currency || DEFAULT_CURRENCY}{' '}
              {product.price - (product.discount || 0)}
            </span>
          </Hidden>
        </Grid>
        <Grid item xs={7} lg={5} className={styles.qtyWrapper}>
          <div style={{ marginBottom: 10 }}>
            <Hidden xsDown>
              {product.price !== product.price - (product.discount || 0) && (
                <span className={styles.priceOld}>
                  {shop?.config?.currency || DEFAULT_CURRENCY} {product.price}
                </span>
              )}
              <span>
                {shop?.config?.currency || DEFAULT_CURRENCY}{' '}
                {product.price - (product.discount || 0)}
              </span>
            </Hidden>{' '}
            {'  '}
            <span>X</span>
            <input
              className={styles.qtyInput}
              value={cartProduct.quantity}
              placeholder="Qty"
              type="number"
              onChange={changeItemQtyInCart}
            />
            <span className={styles.subTotal}>
              {shop?.config?.currency || DEFAULT_CURRENCY}{' '}
              {product.price * cartProduct.quantity}
            </span>
          </div>
        </Grid>
      </Grid>
    </Paper>
  );
}
