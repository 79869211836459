import styles from './styles.module.css';
import { Grid, Hidden, Paper } from '@material-ui/core';
import { getProductImageSrc } from '../../../helpers/ProductHelper';
import { ShopContext } from '../../../contexts/ShopContext';
import { useContext } from 'react';
import { DEFAULT_CURRENCY } from '../../../const';

function OrderItemView({ product, data }) {
  const shop = useContext(ShopContext);
  const orderItem = Object.keys(data || []).find(
    (d) => d.toString() === product?.id
  );
  return (
    <Grid item xs={12} md={4} lg={3}>
      <Paper elevation={1} className={styles.cartCard}>
        <Grid container>
          <Grid xs={4} item>
            <img
              className={styles.productImg}
              src={getProductImageSrc(product)}
              alt={product.name}
            />
          </Grid>
          <Grid xs={8} item className={styles.cartItemNameWrapper}>
            <h3>{product.name}</h3>
            <span>{product.description}</span>
          </Grid>
        </Grid>
        <Grid container style={{ marginTop: 10 }}>
          <Grid item xs={4} lg={5} className={styles.priceWrapper}>
            <Hidden smUp>
              {product.price !== product.price - (product.discount || 0) && (
                <span className={styles.priceOld}>
                  {shop?.config?.currency || DEFAULT_CURRENCY} {product.price}
                </span>
              )}
              <span>
                {shop?.config?.currency || DEFAULT_CURRENCY}{' '}
                {product.price - (product.discount || 0)}
              </span>
            </Hidden>
          </Grid>
          <Grid item xs={8} lg={7} className={styles.qtyWrapper}>
            <div style={{ marginBottom: 10 }}>
              <Hidden xsDown>
                {product.price !== product.price - (product.discount || 0) && (
                  <span className={styles.priceOld}>
                    {shop?.config?.currency || DEFAULT_CURRENCY} {product.price}
                  </span>
                )}
                <span>
                  {shop?.config?.currency || DEFAULT_CURRENCY}{' '}
                  {product.price - (product.discount || 0)}
                </span>
              </Hidden>{' '}
              {'  '}
              <span>X</span>
              <input
                className={styles.qtyInput}
                value={data[orderItem]['quantity']}
                placeholder="Qty"
                type="number"
                disabled
              />
              <span className={styles.subTotal}>
                {shop?.config?.currency || DEFAULT_CURRENCY}{' '}
                {product?.price * data[orderItem]['quantity']}
              </span>
            </div>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}

export default OrderItemView;
