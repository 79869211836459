import stylesClasses from './TitleBar.module.css';
import {
  Badge,
  Grid,
  Hidden,
  InputAdornment,
  OutlinedInput,
} from '@material-ui/core';
import LocalMallIcon from '@material-ui/icons/LocalMall';
import React, { useContext, useState } from 'react';
import CartList from '../../popups/CartListDailog';
import PlaceOrder from '../../popups/PlaceOrderDialog';
import PlaceOrderStatus from '../../popups/OrderStatusDialog';
import SearchList from '../../utils/SearchList';
import IconButton from '@material-ui/core/IconButton';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { withStyles } from '@material-ui/styles';
import { ShopContext } from '../../../contexts/ShopContext';
import { CartContext } from '../../../contexts/CartContext';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { ROUTES } from '../../../const';
import { useHistory } from 'react-router-dom';

const styles = (theme) => ({
  root: {
    '& $notchedOutline': {
      border: 'none',
    },
    '&:hover $notchedOutline': {
      border: 'none',
    },
    '&$focused $notchedOutline': {
      border: 'none',
    },
  },
  focused: {},
  notchedOutline: {},
});

function TitleBar(props) {
  const { search, fnOnSearchTextChange, fnOnClose, classes } = props;
  const history = useHistory();
  const { shop, theme } = useContext(ShopContext);
  const { cart, itemsCount } = useContext(CartContext);

  const [showCartPopup, setShowCartPopup] = useState(false);
  const [showPlaceOrderPopup, setShowPlaceOrderPopup] = useState(false);
  const [showOrderStatusPopup, setShowOrderStatusPopup] = useState(false);
  const [order, setOrder] = useState({});
  const [showSearchPopup, setShowSearchPopup] = useState(false);

  const dismissCartPopup = () => {
    setShowCartPopup(false);
  };

  const dismissPlaceOrderPopup = () => {
    setShowPlaceOrderPopup(false);
  };

  const dismissOrderStatusPopup = () => {
    setShowOrderStatusPopup(false);
  };

  const openPlaceOrderPopup = () => {
    setShowCartPopup(false);
    setShowPlaceOrderPopup(true);
    setShowOrderStatusPopup(false);
  };

  const openOrderStatusPopup = (order) => {
    setOrder(order);
    setShowCartPopup(false);
    setShowPlaceOrderPopup(false);
    setShowOrderStatusPopup(true);
  };

  const openCartPopup = () => {
    setShowCartPopup(true);
    setShowPlaceOrderPopup(false);
    setShowOrderStatusPopup(false);
  };

  const openSearchDialog = () => {
    setShowSearchPopup(!search);
  };

  const closeSearchButtonPressed = () => {
    setShowSearchPopup(false);
  };

  const cartButtonPressed = () => {
    if (itemsCount > 0) {
      setShowCartPopup(true);
    }
  };

  const ordersButtonPressed = () => {
    history.push(ROUTES.ORDERS.replace(':shopId', shop.id));
  };

  const goHomeButtonPressed = () => {
    // Closing the search results dialog
    fnOnClose();
    history.push(ROUTES.SHOP_HOME.replace(':shopId', shop.id));
  };

  return (
    <Grid
      container
      className={stylesClasses.titleBarLayout}
      style={{ backgroundColor: theme.color.primary }}
    >
      <Hidden smDown>
        <Grid item md={1} />
      </Hidden>
      <Hidden xsDown>
        <Grid
          item
          sm={3}
          md={2}
          className={stylesClasses.logoWrapper}
          onClick={goHomeButtonPressed}
        >
          <span className={stylesClasses.logoText}>{shop?.name}</span>
        </Grid>
      </Hidden>
      <Grid item xs={9} sm={6} md={6} className={stylesClasses.searchWrapper}>
        <OutlinedInput
          style={{ outline: 'none' }}
          onClick={openSearchDialog}
          type="search"
          autoFocus={search}
          placeholder="Search products"
          className={stylesClasses.searchInput}
          classes={classes}
          onChange={(e) => fnOnSearchTextChange(e.target.value)}
          endAdornment={
            search && (
              <InputAdornment position="end">
                <IconButton
                  aria-label="Close search"
                  onClick={() => {
                    if (fnOnClose) {
                      fnOnClose();
                    }
                  }}
                  edge="end"
                >
                  <ExitToAppIcon />
                </IconButton>
              </InputAdornment>
            )
          }
        />
      </Grid>
      <Grid item xs={3} sm={3} md={2}>
        <Grid
          container
          alignContent="center"
          alignItems="center"
          justifyContent="center"
          spacing={2}
        >
          <Grid item onClick={cartButtonPressed}>
            <Badge
              overlap="rectangular"
              badgeContent={itemsCount}
              color="primary"
            >
              <ShoppingCartIcon className={stylesClasses.cartLogo} />
            </Badge>
          </Grid>
          <Grid item onClick={ordersButtonPressed}>
            <LocalMallIcon className={stylesClasses.cartLogo} />
          </Grid>
        </Grid>
      </Grid>
      <Hidden smDown>
        <Grid item md={1} />
      </Hidden>
      {showCartPopup && (
        <CartList
          shopId={shop?.id}
          showPopup={true}
          cart={cart}
          dismissPopup={dismissCartPopup}
          openPlaceOrderPopup={openPlaceOrderPopup}
        />
      )}
      {showPlaceOrderPopup && (
        <PlaceOrder
          shop={shop}
          cart={cart}
          showPopup={true}
          fnOnClose={dismissPlaceOrderPopup}
          fnOnBack={openCartPopup}
          openOrderStatusPopup={openOrderStatusPopup}
        />
      )}
      {showOrderStatusPopup && (
        <PlaceOrderStatus
          showPopup={true}
          fnOnClose={dismissOrderStatusPopup}
          order={order}
        />
      )}
      {showSearchPopup && !search && (
        <SearchList
          show={true}
          shop={shop}
          fnOnClose={closeSearchButtonPressed}
        />
      )}
    </Grid>
  );
}

export default withStyles(styles)(TitleBar);
