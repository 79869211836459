import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button, Grid, InputAdornment, TextField } from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import { parse } from 'querystring';
import styles from './styles.module.css';
import CartService from '../../services/CartService';
import OrderService from '../../services/OrderService';
import LoadingDialog from '../utils/LoadingDialog';
import { CartContext } from '../../contexts/CartContext';
import { AlertMessagesContext } from 'react-alert-messages';
import { nanoid } from 'nanoid';
import { DEFAULT_CURRENCY, STORAGE_KEY } from '../../const';

export default function PlaceOrderDialog(props) {
  const { clearCartItems } = useContext(CartContext);
  const { postAlertMessage } = useContext(AlertMessagesContext);
  const { shop, cart, fnOnBack, fnOnClose, openOrderStatusPopup } = props;

  const [customer, setCustomer] = useState({});
  const [errors, setErrors] = useState({});
  const [orderFields, setOrderFields] = useState(null);
  const [orderFieldsTobeCollected, setOrderFieldsTobeCollected] = useState([]);
  const [showLoading, setShowLoading] = useState(false);

  const { search } = useLocation();
  const queryParams = useMemo(() => parse(search.slice(1)), [search]);

  const _getFieldStorageKey = (orderFieldKey) => {
    return `customer-details-${orderFieldKey}`;
  };

  const _getAvailableFieldValue = useCallback(
    (orderFieldKey) => {
      const queryParamsValue = queryParams[orderFieldKey];
      if (queryParamsValue) {
        return queryParamsValue;
      }

      const fieldStorageKey = _getFieldStorageKey(orderFieldKey);
      return localStorage.getItem(fieldStorageKey);
    },
    [queryParams]
  );

  const getOrSetCustomerId = () => {
    const customerId = localStorage.getItem(STORAGE_KEY.CUSTOMER_ID);
    if (customerId) {
      return customerId;
    }

    const newCustomerId = nanoid();
    localStorage.setItem(STORAGE_KEY.CUSTOMER_ID, newCustomerId);
    return newCustomerId;
  };

  const saveOrderToLocalStorage = (order) => {
    const ordersJSON = localStorage.getItem(STORAGE_KEY.ORDERS) || '[]';
    const orders = JSON.parse(ordersJSON);
    orders.push(order);
    localStorage.setItem(STORAGE_KEY.ORDERS, JSON.stringify(orders));
  };

  const _placeOrder = useCallback(
    async (_customer) => {
      try {
        const __customer = { ..._customer, customerId: getOrSetCustomerId() };
        const cart = CartService.getCart(shop.id);
        const order = await OrderService.placeOrder(shop, cart, __customer);
        saveOrderToLocalStorage(order);
        clearCartItems(shop.id);
        fnOnClose();
        if (!shop.config.placeOrderOverWhatsapp) {
          openOrderStatusPopup(order);
        }
      } catch (error) {
        postAlertMessage({ text: error.message, type: 'failed' });
        setShowLoading(false);
      }
    },
    [shop, fnOnClose, clearCartItems, openOrderStatusPopup, postAlertMessage]
  );

  const placeOrderBtnPressed = async () => {
    const _errors = {};

    const requiredFields = orderFieldsTobeCollected.filter(
      (orderField) => orderField.required
    );

    for (const requiredField of requiredFields) {
      if (!customer[requiredField.id]) {
        _errors[
          requiredField.id
        ] = `${requiredField.label} should not be empty`;
      }
    }
    setErrors(_errors);
    if (Object.keys(_errors).length) {
      return;
    }

    await _placeOrder(customer);
  };

  const formValueChanged = (event) => {
    setCustomer({
      ...customer,
      [event.target.name]: event.target.value,
    });
    const keyName = _getFieldStorageKey(event.target.name);
    localStorage.setItem(keyName, event.target.value);
  };

  useEffect(() => {
    if (orderFields == null) {
      return;
    }

    const _customer = {};
    const _orderFieldsTobeCollected = [];
    orderFields.forEach((orderField) => {
      const existingValue = _getAvailableFieldValue(orderField.id);
      _customer[orderField.id] = existingValue || '';
      if (!existingValue || !orderField.skipIfAvailable) {
        _orderFieldsTobeCollected.push(orderField);
      }
    });

    // No field to be collected, and auto placing the order
    if (_orderFieldsTobeCollected.length === 0) {
      return _placeOrder(_customer);
    }

    setCustomer(_customer);
    setOrderFieldsTobeCollected(_orderFieldsTobeCollected);
  }, [orderFields, _placeOrder, _getAvailableFieldValue]);

  useEffect(() => {
    const orderFields = shop?.config?.orderFields || {};
    const sortedOrderFields = Object.keys(orderFields)
      .map((orderFieldKey) => ({
        ...orderFields[orderFieldKey],
        id: orderFieldKey,
      }))
      .filter(
        (orderField) =>
          !orderField.orderType ||
          orderField.orderType === queryParams.orderType
      )
      .sort((first, second) => (first.order || 9999) - (second.order || 9999));

    setOrderFields(sortedOrderFields);
  }, [shop?.config?.orderFields, queryParams.orderType]);

  useEffect(() => {
    setShowLoading(!orderFieldsTobeCollected.length);
  }, [orderFieldsTobeCollected.length]);

  if (showLoading) {
    return (
      <LoadingDialog show={props.showPopup} onClose={props.dismissPopup} />
    );
  }

  return (
    <>
      <Dialog
        open={props.showPopup}
        onClose={props.dismissPopup}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">Place Order</DialogTitle>
        <DialogContent className={styles.popupContentDiv}>
          <Grid container style={{ padding: 16 }}>
            {orderFieldsTobeCollected.map((orderField) => {
              const startAdornment = orderField.prefix ? (
                <InputAdornment position="start">
                  {orderField.prefix}
                </InputAdornment>
              ) : undefined;

              const inputProps = {};
              if (orderField.maxLength) {
                inputProps.maxLength = orderField.maxLength;
              }

              return (
                <Grid item xs={12} key={orderField.id}>
                  <TextField
                    label={orderField.label}
                    name={orderField.id}
                    type={orderField.type}
                    size="small"
                    color="secondary"
                    fullWidth
                    margin="dense"
                    onChange={formValueChanged}
                    value={customer[orderField.id] || ''}
                    helperText={errors[orderField.id]}
                    error={!!errors[orderField.id]}
                    InputProps={{
                      startAdornment,
                    }}
                    inputProps={inputProps}
                  />
                </Grid>
              );
            })}
          </Grid>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'space-between' }}>
          <div className={styles.CartTotalDiv}>
            <div>Subtotal:</div>
            <div>
              <b>
                {shop?.config?.currency || DEFAULT_CURRENCY}{' '}
                {cart.summary?.total}
              </b>
            </div>
          </div>

          <div>
            <Button style={{ color: red[600] }} onClick={fnOnBack}>
              BACK
            </Button>
            <Button
              style={{ color: green[600] }}
              onClick={placeOrderBtnPressed}
            >
              PLACE ORDER
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
}
