import axios from 'axios';
import { formatCart } from '../helpers/MessageFormatter';
import { SERVER } from '../const';

async function placeOrder(shop, cart, customer) {
  if (shop.config.placeOrderOverWhatsapp) {
    return shareCartThroughWhatsapp(shop, cart, customer);
  }
  return placeOrderToBackend(shop, cart, customer);
}

async function placeOrderToBackend(shop, cart, customer) {
  const products = Object.keys(cart.products).map((productId) => {
    const cartProduct = cart.products[productId];
    return { productId, quantity: cartProduct.quantity };
  });
  const order = {
    customer,
    products,
    source: 'online-cart',
  };
  const resp = await axios.post(`${SERVER.URL}/public/sales`, order, {
    params: { orgId: shop.id },
  });
  return resp.data;
}

function shareCartThroughWhatsapp(shop, cart, customer) {
  const cartFormatted = formatCart(cart, shop, customer);
  const cartMsg = encodeURI(cartFormatted);
  window.open(
    `https://api.whatsapp.com/send?text=${cartMsg}&phone=${shop.config.whatsappNumber}`
  );
}

const getOrder = async (order, orgId) => {
  return axios.get(`${SERVER.URL}/public/sales/${order?.id}`, {
    params: { orgId, secretKey: order?.secretKey },
  });
};

const OrderService = {
  placeOrder,
  getOrder,
};

export default OrderService;
