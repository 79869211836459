import React, { useCallback, useContext, useEffect, useState } from 'react';
import TitleBar from '../../utils/TitleBar/TitleBar';
import styles from './Order.module.css';
import { ROUTES, STORAGE_KEY } from '../../../const';
import OrderService from '../../../services/OrderService';
import { ShopContext } from '../../../contexts/ShopContext';
import { AlertMessagesContext } from 'react-alert-messages';
import OrderList from './OrderList';
import { useHistory } from 'react-router-dom';
import { Grid, Hidden } from '@material-ui/core';
import Info from '../../utils/Alerts/Info';
import Loader from '../../utils/Loading';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

function OrderPage() {
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();
  const { shop } = useContext(ShopContext);
  const { postAlertMessage } = useContext(AlertMessagesContext);

  const getOrders = useCallback(
    async (sales) => {
      setIsLoading(true);
      try {
        const promises = sales.map((sale) => {
          return OrderService.getOrder(sale, shop?.id);
        });
        const responses = await Promise.all(promises);
        const responseData = responses.map((response) => response.data);
        setOrders(responseData);
      } catch (error) {
        postAlertMessage({ text: error.message, type: 'failed' });
      }
      setIsLoading(false);
    },
    [shop?.id, postAlertMessage]
  );

  const handleBackToHome = () => {
    history.push(ROUTES.SHOP_HOME.replace(':shopId', shop.id));
  };

  useEffect(() => {
    const sales = localStorage.getItem(STORAGE_KEY.ORDERS);
    if ((sales || []).length) {
      const salesData = JSON.parse(sales);
      getOrders(salesData);
    }
  }, [getOrders]);

  return (
    <>
      <TitleBar />
      <div className={styles.ContentsWrapper}>
        {isLoading && <Loader isOpen={isLoading} />}
        <Grid container className={styles.ContentsArea}>
          <Hidden smUp={true}>
            <Grid
              container
              onClick={handleBackToHome}
              className={styles.backToMenu}
            >
              <ArrowBackIosIcon style={{ fontSize: '14px' }} />
              <span> Back to menu</span>
            </Grid>
          </Hidden>
          {orders && orders.length
            ? orders.map((order) => (
                <OrderList
                  key={order?.id}
                  data={order}
                  link={() =>
                    history.push(
                      ROUTES.ORDER_DETAILS.replace(':shopId', shop.id).replace(
                        ':orderId',
                        order.id
                      )
                    )
                  }
                />
              ))
            : !isLoading && (
                <Info
                  title={'No Orders Found'}
                  content={
                    'There is no active or recent orders. Please make some orders.'
                  }
                />
              )}
        </Grid>
      </div>
    </>
  );
}

export default OrderPage;
