function sort(data) {
  return Array.isArray(data)
    ? data.sort((datum1, datum2) => datum1.order - datum2.order)
    : data;
}

const SortHelper = {
  sort,
};

export default SortHelper;
