import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import styles from '../popups/styles.module.css';
import { Grid } from '@material-ui/core';
import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';

export default function LoadingDialog({ show, onClose }) {
  return (
    <Dialog open={show} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogContent className={styles.popupContentDiv}>
        <Grid
          container
          style={{ padding: 16, minHeight: 300 }}
          justifyContent="center"
          alignItems="center"
          direction="column"
        >
          <CircularProgress />
          <div style={{ marginTop: 20 }}>Loading...</div>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
