import axios from 'axios';
import SortHelper from '../helpers/SortHelper';

const YESCLOUD_URL = 'https://yscloud.in/backend/api/public';

async function getCategories(shop) {
  const extShopId = shop.config.yescloudShopId;
  const resp = await axios.get(
    `${YESCLOUD_URL}/shops/${extShopId}/categories/`
  );
  return SortHelper.sort(resp.data.data);
}

async function getCategory(shop, categoryId) {
  return {};
}

async function getProducts(shop, category, searchKey) {
  const extShopId = shop.config.yescloudShopId;
  const categoryId =
    category && category.id !== 'all' ? category.id : undefined;

  const resp = await axios.get(`${YESCLOUD_URL}/shops/${extShopId}/items/`, {
    params: { category: categoryId, searchKey },
  });
  const products = resp.data.data.map((product) => {
    return {
      id: product.id,
      discountPerUnit: product.mrp ? product.mrp - product.price : 0,
      images: [product.image],
      name: product.name,
      price: product.mrp || product.price,
    };
  });
  return SortHelper.sort(products);
}

async function getProduct(shop, productId) {
  return {};
}

const ProductService = {
  getProducts,
  getCategories,
  getCategory,
  getProduct,
};

export default ProductService;
